html,
body {
  height: 100%;
  margin: 0px;
}

#root {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Recurly Overrid */

.recurly-element-card {
  border-radius: 8px !important;
  height: 40px !important;
}
